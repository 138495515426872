import RichText, { Content } from "../../elements/RichText"
import { Action as ActionType } from '../../elements/Action'
import LogEntry, { LogEntry as LogEntryType } from "../../elements/LogEntry"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export interface Changelog {
    entries: LogEntryType[]
    id: string
    blockType: 'content' | 'call-to-action' | 'change-log'
}

const Changelog = ({ content }: { content: Changelog }) => (
    <div className='section'>
        <div className="col-span-7 ">
            <h2 className="mb-16 ml-4">Changelog</h2>
            <Carousel showStatus={false} renderIndicator={() => { return '' }}>
                {(content.entries ?? []).reverse().map((entry: LogEntryType) => <LogEntry key={entry.id} entry={entry} />)}
            </Carousel>
        </div>
    </div>
)


export default Changelog;