
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import Typewriter from 'typewriter-effect';
import { RiMenuLine, RiVolumeDownFill, RiVolumeMuteFill } from 'react-icons/ri';
import { useContext } from 'react';
import { AudioContext } from '../../providers/AudioContext';


const HUD = ({ activeIndex, toggleOpen }: { activeIndex: number, toggleOpen: () => void }) => {

    const { toggleAudio, audioEnabled } = useContext(AudioContext)

    return (
        <header style={{ height: '83vh' }} className="fixed z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xl:mx-auto w-screen flex xl:max-w-7xl pointer-events-none ">

            <motion.button onClick={toggleOpen} whileHover={{ opacity: [1, 0.9], x: [0, 0.1], transition: { duration: 0.1, repeat: Infinity } }} className='md:block group pointer-events-auto hidden z-50 absolute top-0 left-0 ml-2'>
                <RiMenuLine className='text-glow text-white text-4xl p-1 group-hover:bg-white group-hover:text-gray-700 ' />
            </motion.button>

            <div className='flex absolute bottom-10 md:bottom-0 left-0 h-16 w-16 ml-2'>
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='w-px bg-opacity-0 sm:bg-white ' />
                <div className='h-full w-full'>

                    <div className='h-full w-full flex flex-col ml-4'>
                        <motion.button onClick={toggleAudio} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className="caption m-auto ml-2 sm:mb-4 pointer-events-auto">
                            {audioEnabled ? <RiVolumeDownFill className='text-white text-3xl' /> : <RiVolumeMuteFill className='text-white text-3xl' />}
                        </motion.button>
                    </div>
                    <motion.div initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='h-px bg-opacity-0 sm:bg-white' />
                </div>
            </div>


            <div className='hidden sm:flex absolute bottom-0 h-16 w-full right-0 justify-end mr-2'>
                <div className='h-full w-full flex items-end justify-end '>
                    <div className='flex flex-col justify-end'>
                        <div className='flex items-center space-x-1 justify-end mr-3 mb-2'>
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className='w-full flex flex-col mr-4'>
                                <span className='ml-auto caption m-0 invisible lg:visible'>
                                    <Typewriter onInit={(typewriter) => {
                                        typewriter.pauseFor(1500).changeDelay(100).typeString(`${format(new Date(), 'MMMM, do')}`)
                                            .start();
                                    }} />
                                </span>
                                <span className='ml-auto caption mt-0 invisible lg:visible'>
                                    <Typewriter onInit={(typewriter) => {
                                        typewriter.pauseFor(3000).changeDelay(100).typeString(`Session ${Math.round(Math.random() * 100000000)}`)
                                            .start();
                                    }} />
                                </span>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1, transition: { duration: 2, delay: 1 } }}>
                                <motion.div animate={{ rotate: 360 }} transition={{ duration: 2, ease: 'linear', repeat: Infinity, repeatType: 'loop' }} className='w-8 h-8 lg:h-9 origin-center transform lg:w-9 relative'>
                                    <motion.div animate={{ top: '1rem', left: '1rem' }} transition={{ duration: 1, type: 'tween', ease: 'linear', repeat: Infinity, repeatType: 'mirror' }} className="rounded-full top-0 absolute left-0 border border-white h-4 w-4 lg:h-5 lg:w-5" />
                                    <motion.div animate={{ top: '1rem', left: '0rem' }} transition={{ duration: 1, type: 'tween', ease: 'linear', repeat: Infinity, repeatType: 'mirror' }} className="rounded-full top-0 absolute left-4 border border-white h-4 w-4 lg:h-5 lg:w-5" />
                                    <motion.div animate={{ top: '0rem' }} transition={{ duration: 1, ease: 'linear', type: 'tween', repeat: Infinity, repeatType: 'mirror' }} className="rounded-full top-4 left-2 border absolute border-white h-4 w-4 lg:h-5 lg:w-5" />
                                </motion.div>
                            </motion.div>
                        </div>
                        <div className='w-16 h-min absolute right-0'>
                            <motion.div initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='h-px bg-white' />
                        </div>
                    </div>

                </div>
                <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='w-px bg-white ' />
            </div>

            <div className='hidden sm:flex flex-col top-0 right-0 absolute mr-2'>
                <div className='w-16 h-min absolute right-0'>
                    <motion.div initial={{ width: 0, opacity: 0 }} animate={{ width: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='h-px bg-white' />
                </div>

                <div className='flex h-min w-min justify-end'>
                    <motion.div initial={{ opacity: 0, scale: 0.95 }} animate={{ opacity: 1, scale: 1, transition: { delay: 2, duration: 1 } }} className='grid grid-cols-3 gap-2 lg:gap-4 mt-6 mr-6'>
                        {Array.from({ length: 12 }, (_, i) => <motion.div initial={{ opacity: 0.4 }} animate={{ scale: 2, opacity: 1 }} transition={{ repeat: Infinity, repeatType: 'mirror', ease: 'linear', duration: 2, delay: 1 * (0.3 * i) }} className='h-0.5 w-0.5 bg-white rounded-full' key={i} />)}
                    </motion.div>

                    <div className='h-16 w-full'>
                        <motion.div initial={{ height: 0, opacity: 0 }} animate={{ height: '100%', opacity: 1, transition: { delay: 0.5, duration: 2 } }} className='w-px bg-white ' />
                    </div>
                </div>
            </div>
        </header>
    )
}


export default HUD