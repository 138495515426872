import { motion } from "framer-motion";
import Link from "next/link";
import React, { ReactNode } from "react";

import { SiDiscord, SiYoutube, SiTwitch, SiTwitter, SiSoundcloud, SiSpotify } from 'react-icons/si'

export interface Action {
    link: Link
    id: string
}

interface Link {
    label: string
    icon: 'discord' | 'youtube' | 'twitter' | 'soundcloud' | 'spotify'
    style: 'icon' | 'button' | 'unstyled'
    type: 'page' | 'customUrl'
    url: string
}

const render = (link: Link, index: number) => {
    switch (link.style) {
        case 'icon':
            return <IconButton icon={link.icon} />
        case 'button':
            return index === 0 ? <PrimaryButton label={link.label} /> : <SecondaryButton label={link.label} />;
        case 'unstyled':
            return <div>{link.label}</div>
        default:
            return <div></div>
    }
}

const Action = ({ action, index }: { action: Action, index: number }) => {
    return (
        <Link href={action.link.url}>
            <motion.button transition={{ duration: 0 }} whileHover={{
                opacity: [1, 0.85], skewX: [0, -1], transition: {
                    ease: 'linear',
                    duration: 0.1,
                    type: 'tween',
                    repeat: Infinity
                }
            }}>
                {
                    render(action.link, index)
                }
            </motion.button>
        </Link>
    )
}

const PrimaryButton = ({ label }: { label: string }) => (
    <div className="h-min w-full relative flex items-center justify-center">
        <svg className="hidden md:block" width="270" viewBox="0 0 270 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.03217e-06 69L0 0L235.895 -2.06226e-05L270 69L6.03217e-06 69Z" fill="white" />
        </svg>
        <svg width="155" className="block md:hidden" height="44" viewBox="0 0 155 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.790096 44L0 0L135.158 -1.26027e-05L155 44L3.8466e-06 44Z" fill="white" />
        </svg>
        <div className="absolute my-auto mr-4 font-sans text-button text-gray-700 md:text-button-lg uppercase">{label}</div>
    </div>
)



const SecondaryButton = ({ label }: { label: string }) => (
    <div className="h-min w-full relative flex items-center justify-center">
        <svg className="hidden md:block" width="261" height="69" viewBox="0 0 261 69" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M260 0.999977L260 68L33.5989 68L1.58608 1L260 0.999977Z" stroke="white" strokeWidth="2" />
        </svg>
        <svg className="block md:hidden" width="155" height="44" viewBox="0 0 155 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.790096 0.499999L155 0.499985L155 43.5L21.1585 43.5L0.790096 0.499999Z" stroke="white" />
        </svg>
        <div className="absolute my-auto ml-4 font-sans text-button text-white md:text-button-lg uppercase">{label}</div>
    </div>
)

const IconButton = ({ icon }: { icon: 'discord' | 'youtube' | 'twitter' | 'soundcloud' | 'spotify' }) => {
    const renderIcon = () => {
        switch (icon) {
            case 'discord':
                return <SiDiscord className="h-full w-full group-hover:text-gray-700 text-white group-hover:bg-white p-0.5" />;
            case 'youtube':
                return <SiYoutube className="h-full w-full group-hover:text-gray-700  text-white group-hover:bg-white p-0.5" />;
            case 'twitter':
                return <SiTwitter className="h-full w-full group-hover:text-gray-700 text-white group-hover:bg-white p-0.5" />;
            case 'soundcloud':
                return <SiSoundcloud className="h-full w-full group-hover:text-gray-700 text-white group-hover:bg-white p-0.5" />;
            case 'spotify':
                return <SiSpotify className="h-full w-full group-hover:text-gray-700 text-white group-hover:bg-white p-0.5" />;
            default:
                return <SiDiscord className="h-full w-full group-hover:text-gray-700 text-white group-hover:bg-white p-0.5" />;
        }
    }

    return <motion.div whileHover={{ opacity: [1, 0.9], x: [0, 0.1], transition: { duration: 0.1, repeat: Infinity } }} className=" w-6 h-6 md:w-8 md:h-8 group">{renderIcon()}</motion.div>

}

export default Action
