import React, { ReactNode } from "react";

export interface Content {
    children: [{
        text?: string
    }]
    type?: 'h1' | 'h2' | 'h3'
}

const RichText = ({ content }: { content: Content }) => {
    switch (content.type) {
        case 'h1':
            return <h1>{content.children[0].text ?? ''}</h1>
        case 'h2':
            return <h2>{content.children[0].text ?? ''}</h2>
        case 'h3':
            return <h3>{content.children[0].text ?? ''}</h3>
        case undefined:
            return <p>{content.children[0].text ?? ''}</p>
        default:
            return <p>{content.children[0].text ?? ''}</p>
    }
}

export default RichText