
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import { useState } from 'react'
import { AiFillCaretRight } from 'react-icons/ai'
import { RiMenuLine, RiCloseLine } from 'react-icons/ri'
import mobileLogo from '../../../public/images/mobile-logo.webp'



const Menu = ({ menuItems, activeIndex, navigateTo, isOpen, toggleOpen }: { menuItems: { name: string }[], activeIndex: number, isOpen: boolean, navigateTo: (index: number) => void, toggleOpen: () => void }) => {

    const navigate = (i: number) => {
        toggleOpen();
        navigateTo(i);
    }

    return (

        <nav className={`w-full flex max-w-7xl h-full sm:ml-6 px-4 sm:px-0 pt-4 `}>
            <motion.div initial={{ x: 100, opacity: 0 }} animate={{ opacity: [0, 0.5, 0.5, 0.4, 0.5, 1], x: [10, 0, -10, -10, 5, 0], y: [0, 0, 0, 10, 20, 0], transition: { duration: 0.1, delay: 1, ease: 'linear' } }} className='flex sm:block mt-0 sm:mt-16 sm:-ml-4 w-full justify-between'>
                <div className='relative flex sm:hidden'>
                    <Image alt="mavericks playlist logo" src={mobileLogo} />
                </div>
                <motion.button onClick={() => toggleOpen()} whileHover={{ opacity: [1, 0.9], x: [0, 0.1], transition: { duration: 0.1, repeat: Infinity } }} className='md:hidden block group z-50'>
                    <RiMenuLine className='text-glow text-white text-4xl p-1 group-hover:bg-white group-hover:text-gray-700 ' />
                </motion.button>

            </motion.div>
            <motion.div layout className="fixed z-40 top-0 left-0 w-full touch-none bg-black/30 bg-background backdrop-blur-2xl" initial={false} animate={{ height: isOpen ? '100vh' : 0, transition: { duration: 0.4, delay: isOpen ? 0 : 0.3 } }}>
                <ul className={`flex space-y-4 lg:space-y-8 ml-16 lg:pl-0 justify-center ${isOpen ? 'pointer-events-auto' : 'pointer-events-none '} flex-col text-xl h-full`}>
                    {
                        menuItems.map((x, i) => (
                            <motion.li
                                key={x.name}

                                animate={{
                                    opacity: isOpen ? 1 : 0,
                                    x: isOpen ? 0 : -30,
                                    transition: {
                                        delay: isOpen ? 0.3 : 0.05,
                                        type: 'tween'
                                    }
                                }}
                                initial={false}
                            >
                                <MenuItem showCaret={activeIndex === i} navigate={() => navigate(i)} key={i} >{x.name}</MenuItem>
                            </motion.li>
                        ))
                    }
                    <motion.li animate={{
                        x: isOpen ? 0 : -30,
                        opacity: isOpen ? 1 : 0,
                        transition: {
                            delay: isOpen ? 0.3 : 0.05,
                            type: 'tween'

                        }
                    }}
                        initial={false}
                    >
                        <motion.button whileHover={{ opacity: [1, 0.9], x: [0, 0.1], transition: { duration: 0.1, repeat: Infinity } }} className='mr-auto ml-2 group ' onClick={toggleOpen} aria-label='close menu' initial={false}>
                            <RiCloseLine className="text-4xl text-white group-hover:text-gray-700 group-hover:bg-white" />
                        </motion.button>
                    </motion.li>
                </ul>
            </motion.div>
        </nav>
    )
}


export const MenuItem = ({ children, showCaret, navigate }: { children: any, showCaret: boolean, navigate: () => void }) => (
    <motion.button onClick={navigate} className='my-2 group flex items-center cursor-pointer uppercase'>

        <AnimatePresence>
            {showCaret &&
                <motion.div key={children} initial={{ x: -10, opacity: 0 }} animate={{ x: 0, opacity: 0.5, }} exit={{ x: -10, opacity: 0 }}>
                    <AiFillCaretRight className='text-white block' />
                </motion.div>
            }
        </AnimatePresence>
        <motion.h5 initial={{}} whileHover={{ opacity: [1, 0.9], x: [0, 0.1], transition: { duration: 0.1, repeat: Infinity } }} className='text-white group-hover:text-gray-700 group-hover:bg-white px-4 py-0.5'>{children}</motion.h5>
    </motion.button>
)

export default Menu;