import Column, { Column as ColumnType } from "../../layout/Column"

export interface ContentBlock {
    columns: ColumnType[]
    id: string
    blockType: 'content' | 'call-to-action' | 'change-log'
    blockName: string
}


const ContentBlock = ({ content }: { content: ContentBlock }) => {
    return (
        <div className='section gap-8'>
            {content.columns.map((x: ColumnType) => <Column key={x.id} column={x} />)}
        </div>
    )
}


export default ContentBlock