import Action, { Action as ActionType } from '../../elements/Action'
import PayloadImage, { ImageType } from '../../elements/Image'
import RichText, { Content } from '../../elements/RichText'
import Seperator from '../../elements/Seperator'

export interface Row {
    contentType: 'image' | 'richText' | 'seperator' | 'actions'
    alignment: 'left' | 'center' | 'right'
    image?: ImageType
    actions: ActionType[]
    id: string
    content?: Content[]
    seperator?: boolean
}


export const alignment = (align: 'left' | 'center' | 'right') => {
    switch (align) {
        case 'left':
            return 'justify-start text-center lg:text-left'
        case 'center':
            return 'justify-center text-center'
        case 'right':
            return 'justify-end text-center lg:text-right'
        default:
            return 'justify-start text-center lg:text-left'
    }
}


const renderContent = (row: Row) => {
    switch (row.contentType) {
        case 'actions':
            return <div className='flex justify-center mx-auto lg:mx-0'>{row.actions.map((x: ActionType, i: number) => <Action index={i} key={i} action={x} />)}</div>
        case 'richText':
            return (row.content ?? []).map((x: Content, i: number) => <RichText key={i} content={x} />)
        case 'seperator':
            return row.seperator && <Seperator />
        case 'image':
            return row.image && <PayloadImage image={row.image} />
        default:
            return (row.content ?? []).map((x: Content) => <RichText key={x.type} content={x} />)
    }
}

const Row = ({ row, align }: { row: Row, align: 'left' | 'center' | 'right' }) => (
    <div className={`lg:p-2 w-full flex ${alignment(align)} ${row.contentType === 'richText' && 'flex-col'}`}>
        {renderContent(row)}
    </div>
)



export default Row