import { AnimatePresence, motion } from "framer-motion";
import React from "react"
import CallToAction from "../../blocks/CallToAction";
import Changelog from "../../blocks/Changelog";
import ContentBlock from "../../blocks/ContentBlock";
import { AiFillCaretDown } from "react-icons/ai";
import Footer, { Type as FooterType } from '../Footer'

export const glitchVariants = {
    exit:
        { opacity: [1, 1, 1, 1, 1, 0, 0.5, 0.1, 0, 0, 0.1, 0, 0, 0.1, 0.1, 0.1, 0, 0.1, 0, 0.1, 0.4, 0.4, 0.4, 0.4, 0], scale: [1, 1, 1, 1, 1, 0.9, 1.5, 1.5, 1.5, 1, 1.5, 1.5, 1.5, 2, 2, 1.5, 1.5, 1.5, 1], y: [0, 0, 0, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 0, 0, 0, 0], x: [0, 0, 0, 0, 0, 0, 0, 0, -200, -200, -200, -200, -200, -200, -200, -200, -200, -200, -200, 0], filter: ['brightness(1)', 'brightness(1)', 'contrast(200%)', 'brightness(1)'], transition: { duration: 0.2 } },
    initial: {
        opacity: 0, scale: 1, x: 0
    },
    animate: {
        opacity: [0, 0.6, 0, 0.5, 1, 1, 0, 1, 0.3, 0.3, 0.3, 1], scale: [1, 1, 1.1, 0.9, 1], x: [0, 0, 50, 50, 0, 0, 0, 0, -20, -20, -20, 0, 0, 0, 0, 0, 0], y: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 20, 20, 20, 0], transition: { duration: 0.3, ease: [100, 100, 0, 0] }
    }

}


export const SectionItem = ({ children, index, activeIndex, footer, length, scrollDown }: { children: any, index: number, footer: FooterType, activeIndex: number, length: number, scrollDown: () => void }) => {

    const render = (content: any) => {
        switch (content.blockType as 'content' | 'call-to-action' | 'change-log') {
            case 'content':
                return <ContentBlock content={content} key={content.id} />
            case 'call-to-action':
                return <CallToAction content={content} key={content.id} />
            case 'change-log':
                return <Changelog content={content} key={content.id} />
            default:
                return <ContentBlock content={content} key={content.id} />
        }
    }



    return (
        <AnimatePresence initial={false} mode='popLayout'>
            {
                activeIndex === index &&
                <motion.div layout variants={glitchVariants} key={index} exit='exit' initial='initial' animate='animate' className='relative justify-center items-center overflow-hidden flex h-85vh sm:h-screen'>
                    {render(children)}
                    <h5 className="absolute -right-20 lg:right-64 z-0 text-white h-min -bottom-72 font-bold" style={{ fontSize: '350pt', opacity: 0.03 }}>
                        {`0` + (activeIndex + 1)}
                    </h5>
                    {
                        activeIndex < (length - 1) && <motion.button whileHover={{ opacity: [1, 0.9], transition: { duration: 0.1, repeat: Infinity } }} onClick={scrollDown} animate={{ opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0] }} transition={{ duration: 1.5, repeat: Infinity, ease: 'backInOut' }} className="bottom-0 sm:bottom-4 md:bottom-8 absolute mx-auto left-1/2 -translate-x-1/2 flex flex-col justify-center group">
                            <span className="mb-0 text-white text-xs lg:text-lg uppercase font-sans text-center group-hover:text-gray-700 group-hover:bg-white">Page Down</span>
                            <div className="mx-auto group-hover:bg-white">
                                <AiFillCaretDown className='text-2xl lg:text-2xl group-hover:text-gray-700 text-white' />
                            </div>
                        </motion.button>
                    }
                </motion.div>
            }
        </AnimatePresence>
    )
}


const SectionDisplay = ({ sections, activeIndex, footer, scrollDown }: { sections: any[], activeIndex: number, footer: FooterType, scrollDown: () => void }) => {
    return (
        <div className="overflow-hidden">
            {sections.map((section, i) => <SectionItem footer={footer} index={i} activeIndex={activeIndex} key={i} length={sections.length} scrollDown={scrollDown}>{section}</SectionItem>)}
        </div>)
}


export default SectionDisplay