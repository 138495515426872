import RichText, { Content } from "../../elements/RichText"
import Action, { Action as ActionType } from '../../elements/Action'
import PayloadImage, { ImageType } from "../../elements/Image"
import Image from "next/image"

import frameBg from '../../../images/frame-bg.svg'
import frameAll from '../../../images/frame-all.svg'

export interface CallToAction {
    content: Content[]
    image: ImageType,
    actions: ActionType[]
    id: string
    blockType: 'content' | 'call-to-action' | 'change-log'
}

const CallToAction = ({ content }: { content: CallToAction }) => {
    return (
        <div className='section lg:space-x-8'>
            <div className="lg:basis-5/12 self-center">
                <PayloadImage image={content.image} />
            </div>
            <div className="relative w-full flex justify-center items-center lg:basis-7/12 ">
                <div className='z-10 flex left-0 top-0 sm:w-1/2 md:w-2/3 lg:w-full xl:w-5/6 h-full relative'>
                    <Image layout='intrinsic' objectFit='contain' src={frameAll} className='text-glow' />
                    <div className="absolute w-full h-full left-4">
                        <Image layout='intrinsic' objectFit='contain' className=" blur-md " style={{ transform: 'scale(0.99)' }} src={frameBg} />
                    </div>
                    <div className="flex h-min w-min flex-col space-y-12 text-center lg:text-left absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        {(content.content ?? []).map((x: Content) => <RichText key={x.type} content={x} />)}
                        <div className="flex lg:h-full justify-between px-4 relative items-center w-full">
                            {(content.actions ?? []).map((x: ActionType, i: number) => <Action key={i} action={x} index={i} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallToAction;