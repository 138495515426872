import React from "react";
import { motion } from 'framer-motion';

const Seperator = () => (
    <div className="relative w-full lg:visible invisible my-2">
        <div className="h-1 bg-white w-full" />
        <motion.div animate={{ scale: [1, 1.035, 1.035, 1.01, 1.025, 1.02, 1.02, 1.02, 1.02, 1.05, 1.035, 1.035, 1.045, 1.005, 1.005, 1.005, 1.005, 1.035, 1.035, 1.01, 1.025, 1.02, 1.02, 1.025, 1.02, 1.02, 1.02, 1.02, 1.05, 1.035, 1.035, 1.045, 1.005, 1.035, 1.01, 1.025, 1.02, 1.02, 1.025, 1.02, 1.02, 1.02, 1.02, 1.05, 1.035, 1.035, 1.045, 1.005, 1.005, 1.005, 1.005, 1.005, 1.03, 1] }}
            transition={{
                ease: 'linear',
                duration: 1,
                type: 'tween',
                repeat: Infinity, repeatDelay: 0.5
            }} className="bg-white h-7 w-7 rounded-full top-0 bottom-0 absolute my-auto left-8">
            <div className=" bg-bg-gradient-dark h-6 w-6 top-0 bottom-0 left-0 right-0 rounded-full absolute m-auto ">
                <div className="bg-white h-4 w-4 top-0 bottom-0 left-0 right-0 rounded-full absolute m-auto " />
            </div>
        </motion.div>
    </div>
)

export default Seperator