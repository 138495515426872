import { AnimatePresence, motion } from 'framer-motion'
import React, { FC, useContext, useEffect, useState } from 'react'
import { ContentBlock as SectionType } from '../components/blocks/ContentBlock'
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import HUD from '../components/globals/HUD'
import Menu from '../components/globals/Menu'
import SectionDisplay from '../components/globals/Section'
import LoadingScreen from '../components/globals/Loading';
import useSound from 'use-sound';
import { GetStaticPaths, GetStaticProps } from 'next';
import PreviewMessage from '../components/globals/PreviewMessage';
import { Type as LoadingScreenType } from '../components/globals/Loading'
import { Type as FooterType } from '../components/globals/Footer'
import { NextSeo } from 'next-seo';
import { AudioContext } from '../components/providers/AudioContext';
import { Howl } from 'howler';
import { ImageType } from '../components/elements/Image';

interface Page {
    page: {
        id: string
        meta: {
            title: string,
            description: string
            image: ImageType
        }
        sections: SectionType[]
        slug: string
        title: string
    },
    statusCode: number,
    preview: boolean,
    footer: FooterType,
    loadingScreen: LoadingScreenType
}

const glitches = ['glitch1', 'glitch2', 'glitch3', 'glitch4', 'glitch5', 'glitch6', 'glitch7', 'glitch8', 'glitch9']

const Page: FC<Page> = (props) => {
    const { page, preview, footer, loadingScreen } = props;

    const { audioEnabled } = useContext(AudioContext)

    const sounds = {
        volume: 0.2, playbackRate: 0.5, sprite: {
            glitch1: [1000, 200],
            glitch2: [5000, 200],
            glitch3: [6000, 200],
            glitch4: [7320, 200],
            glitch5: [7000, 200],
            glitch6: [8000, 200],
            glitch7: [11000, 200],
            glitch8: [12100, 200],
            glitch9: [13000, 200]
        }
    }
    const glitch = useSound('/sounds/glitch.mp3', sounds as any)

    const [activeIndex, setActiveIndex] = useState(0);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true)
    const [disableScroll, setDisableScroll] = useState(true)
    const [isOpen, setIsOpen] = useState(true)
    const [backgroundSound, setBackgroundSound] = useState(new Howl({
        src: ['/sounds/drone1.mp3'],
        loop: true,
        volume: 0
    }))

    const playGlitch = (index: number) => {
        if (audioEnabled) {
            glitches[Math.floor(Math.random() * glitches.length)]
            glitch[0]({ id: glitches[index] })
        }
    }

    const scrollUp = (e: WheelEvent) => {
        const canNavigate = activeIndex > 0
        if (canNavigate) {
            playGlitch(activeIndex - 1);
            setActiveIndex(activeIndex - 1)
            return true
        }
    }

    const navigateTo = (index: number) => {
        setIsOpen(false)
        playGlitch(index);
        setActiveIndex(index)
    }

    const scrollDown = (e: WheelEvent) => {
        const canNavigate = activeIndex !== page.sections.length - 1
        if (canNavigate) {
            playGlitch(activeIndex + 1);
            setActiveIndex(activeIndex + 1);
            return true
        }
    }

    const setLoaded = () => {
        setShowLoadingScreen(false);
        setDisableScroll(false)
        setIsOpen(!isOpen)
    }

    const toggleOpen = () => {
        setDisableScroll(!disableScroll)
        setIsOpen(!isOpen)
    }

    const start = () => {
        console.log('start')
        var backgroundPlay = backgroundSound.play();
        setBackgroundSound(backgroundSound.fade(0, 0.3, 2000, backgroundPlay))
    }

    useEffect(() => {
        const skipLoadingScreen = typeof window !== 'undefined' && localStorage.getItem('skipLoadingScreen');
        if (skipLoadingScreen !== null) {
            setShowLoadingScreen(false);
            setDisableScroll(false)
        }
    }, []);


    useEffect(() => {
        console.log('mute changed')

        if (!audioEnabled)
            backgroundSound.volume(0);
        else
            backgroundSound.volume(0.3)

    }, [audioEnabled])

    return (
        <>
            <NextSeo canonical='https://mavericksplaylist.com/' twitter={{ handle: '@MavsPlaylist', cardType: 'summary_large_image' }} openGraph={{
                title: page.meta.title,
                description: page.meta.description,
                images: [
                    {
                        width: page.meta.image.width,
                        height: page.meta.image.height,
                        alt: page.meta.image.alt,
                        url: page.meta.image.url,
                        type: page.meta.image.mimeType
                    }
                ],
                site_name: page.meta.title
            }} title={page.meta.title} description={page.meta.description} titleTemplate="Maverick's Playlist | %s" />
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <ReactScrollWheelHandler pauseListeners={disableScroll} upHandler={scrollUp} downHandler={scrollDown} timeout={800}>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 0.2 }} className='background' />
                    {preview && <PreviewMessage />}
                    <AnimatePresence>
                        {
                            showLoadingScreen ?
                                <LoadingScreen startButtonCallback={start} loaded={setLoaded} loadingText={loadingScreen.loadingText} logo={loadingScreen.logo} key='loading screen' /> : <div key='layout'>
                                    <HUD toggleOpen={toggleOpen} activeIndex={activeIndex} />
                                    <div className='fixed w-screen z-50 flex justify-center'>
                                        <Menu isOpen={isOpen} toggleOpen={toggleOpen} activeIndex={activeIndex} navigateTo={navigateTo} menuItems={page.sections.map((x: SectionType) => ({ name: x.blockName }))} />
                                    </div>
                                    <main className='z-10 relative'>
                                        <SectionDisplay footer={footer} scrollDown={() => navigateTo(activeIndex + 1)} activeIndex={activeIndex} sections={page.sections} />
                                    </main>
                                </div>
                        }
                    </AnimatePresence>
                </ReactScrollWheelHandler>
            </motion.div>
        </>
    )
}

export const getStaticProps: GetStaticProps = async (ctx) => {
    const slug = ctx.params?.slug || "home";

    const pageData = await fetch(`${process.env.CMS_HOST}/api/pages?where[slug][equals]=${slug}${ctx.preview && ctx.preview === true ? '&draft=true' : ''} `).then(res => res.json())

    return {
        props: {
            page: pageData.docs[0],
            preview: ctx.preview && ctx.preview === true ? true : false,
        },
        revalidate: 30
    };
}

export const getStaticPaths: GetStaticPaths = async () => {

    const pageData = await fetch(`${process.env.CMS_HOST}/api/pages?limit=100`).then(res => res.json());

    return {
        paths: pageData.docs.map(({ slug }: { slug: any }) => ({
            params: { slug: slug.split('/') },
        })),
        fallback: false,
    };
};

export default Page