import { motion, useMotionValue, useTransform } from 'framer-motion'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import frame from '../../../public/images/Frame.png'

export interface ImageType {
    id: string
    alt: string
    filename: string
    mimeType: string
    filesize: number
    width: number
    height: number
    sizes: Sizes
    createdAt: string
    updatedAt: string
    url: string
}

interface Sizes {
    card: Size
    feature: Size
}

interface Size {
    width: number
    height: number
    mimeType: string
    filesize: number
    filename: string
    url: string
}


const PayloadImage = ({ image }: { image: ImageType }) => {
    const [rotation, setRotation] = useState(0)

    useEffect(() => setRotation(Math.floor(Math.random() * 360)), [])
    return (
        <div className='relative z-10 mx-auto aspect-video w-2/3 lg:w-5/6 flex'>
            <div style={{ transform: `rotate(${rotation}deg) scale(1.5)`, }} className='absolute  h-full w-full items-center flex justify-center'>
                <Image layout='intrinsic' className='object-left parallax opacity-50' objectFit='contain' src={frame} />
            </div>
            <div className='z-10 h-min '>
                <Image placeholder='blur' blurDataURL={image.url} priority layout='intrinsic' objectFit='cover' src={image.url} height={image.height} width={image.width} />
            </div>
        </div>
    )
}

export default PayloadImage