import { AnimatePresence, LayoutGroup, motion, useAnimationControls } from "framer-motion"
import Image from "next/image";
import { FC, useContext, useEffect, useState } from "react"
import { glitchVariants } from "../Section";
import Typewriter from 'typewriter-effect';
import { ImageType } from "../../elements/Image";
import Action from '../../elements/Action'
import useSound from "use-sound";
import { Howl, Howler } from 'howler';
import { AudioContext } from "../../providers/AudioContext";

export type Type = {
    startButtonCallback: () => void
    logo: ImageType,
    loadingText: { text: string }[],
    loaded: () => void
}


const LoadingScreen: FC<Type> = (props) => {

    const { audioEnabled } = useContext(AudioContext)

    const glitch = useSound('/sounds/glitch.mp3', {
        volume: 0.2,
        playbackRate: 0.6,
        sprite: {
            glitch: [7320, 200],
            glitch2: [8320, 500],
        }
    })

    const [play] = useSound('/sounds/startup.mp3', { volume: 1.5, playbackRate: 0.5 })

    const [text, setText] = useState(props.loadingText[0].text);
    const [startAnimation, setStartAnimation] = useState(false);

    const animate = () => {

        props.startButtonCallback();

        const placeholderText = props.loadingText.map(x => x.text)

        glitch[0]({ id: 'glitch' })

        setStartAnimation(true)

        setTimeout(() => setText(placeholderText[1]), 500)
        setTimeout(() => setText(placeholderText[2]), 1500)
        setTimeout(() => setText(placeholderText[3]), 1000)
        setTimeout(() => play(), 4000)
        setTimeout(() => {
            props.loaded();
            glitch[0]({ id: 'glitch2' })
        }, 5000)
    }

    return (
        <LayoutGroup>
            <motion.div variants={glitchVariants} exit='exit' className="relative w-screen h-85vh md:h-screen flex flex-col pt-4 lg:p-10">
                <div>
                    <ul className="text-white lg:space-y-3 font-light hidden md:flex flex-col items-end tracking-widest uppercase">
                        <li aria-hidden>
                            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className="caption m-auto ml-2 text-sm">
                                <Typewriter onInit={(typewriter) => {
                                    typewriter.changeDelay(200).typeString(`Maverick's Playlist`)
                                        .start();
                                }} /></motion.span>
                        </li>
                        <li aria-hidden>
                            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className="caption m-auto ml-2 text-sm">
                                <Typewriter onInit={(typewriter) => {
                                    typewriter.pauseFor(500).changeDelay(100).typeString(`Revision 1.0.2`)
                                        .start();
                                }} /></motion.span>
                        </li>
                        <li aria-hidden>
                            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className="caption m-auto ml-2 text-sm">
                                <Typewriter onInit={(typewriter) => {
                                    typewriter.pauseFor(1000).changeDelay(700).typeString('en/us')
                                        .start();
                                }} /></motion.span>
                        </li>
                        <li aria-hidden>
                            <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }} className="caption m-auto ml-2 text-sm">
                                <Typewriter onInit={(typewriter) => {
                                    typewriter.pauseFor(1200).changeDelay(100).typeString(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                        .start();
                                }} /></motion.span>
                        </li>
                    </ul>
                </div>
                <div className="m-auto">
                    <motion.div initial={{ y: 100, opacity: 0 }} animate={{
                        y: 0, opacity: 1, transition: {
                            type: 'tween', ease: 'easeIn'
                        }
                    }}>
                        <div className="mx-auto w-32 h-32 md:h-48 md:w-48 flex items-center relative justify-center">
                            <Image alt={props.logo.alt} layout='fill' objectFit='contain' src={props.logo.url} />
                        </div>

                        <AnimatePresence>
                            {
                                startAnimation ? <>
                                    <motion.div key="loader" initial={{ opacity: 1, y: 0 }} animate={{ opacity: 0, y: 100, transition: { delay: 4 } }}>
                                        <p aria-hidden className="text-xl px-8 text-center lg:text-4xl font-light font-sans tracking-wider uppercase">{text}</p>
                                        <div className="mx-auto w-48 lg:w-96">
                                            <motion.div initial={{ width: 0 }} animate={{
                                                width: '100%', transition: {
                                                    duration: 4, type: 'tween', ease: 'linear'
                                                }
                                            }} className=" border border-white opacity-90" />
                                        </div>
                                        <p aria-hidden className='uppercase text-white/70 text-sm text-center opacity-80'>revision number: 1.0.2</p>
                                    </motion.div>

                                    <motion.div initial={{ y: 100, opacity: 0 }} animate={{
                                        opacity: 1, y: -100, transition: {
                                            type: 'spring', bounce: 0.1, delay: 4
                                        }
                                    }} className='w-full'>
                                        <motion.p className="text-xl lg:text-4xl text-center font-light font-sans tracking-wider uppercase opacity-90" aria-hidden>program loaded</motion.p>
                                    </motion.div>
                                </> :
                                    <motion.button key='loadSite' onClick={animate} className="border-2 uppercase py-6 px-12 text-white border-white mt-8 text-glow"
                                        transition={{ duration: 0 }}
                                        whileHover={{
                                            opacity: [1, 0.85], skewX: [0, -1], transition: {
                                                ease: 'linear',
                                                duration: 0.1,
                                                type: 'tween',
                                                repeat: Infinity
                                            }
                                        }}>
                                        Start Program
                                    </motion.button>


                            }
                        </AnimatePresence>

                    </motion.div>

                </div>




                <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.5 } }} className="mx-auto flex flex-col text-center items-center max-w-xs mb-4 lg:max-w-xl">
                    <p className=" w-fit text-black text-lg uppercase font-title py-2 px-4 bg-white ">
                        seizure warning
                    </p>
                    <p className="text-white text-sm">
                        This website or associated videos may potentially trigger seizures for people with photosensitive epilepsy. Viewer discretion is advised.
                    </p>
                </motion.div>

            </motion.div>
        </LayoutGroup>
    )
}


export default LoadingScreen