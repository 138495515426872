import React from "react";
import { motion } from 'framer-motion';
import { format, parseISO, toDate } from "date-fns";

export type LogEntry = {
    date: string
    title: string
    text: string
    id: string
}

const LogEntry = ({ entry }: { entry: LogEntry }) => (
    <article className="md:grid md:grid-cols-12 align-top text-left content-start min-h-full items-stretch md:space-x-16 space-y-4 flex flex-col mx-auto px-12 md:mx-0">
        <span className="text-lg uppercase mt-0 text-glow mr-8 md:mr-0 ">{entry.title}</span>
        <div className="bg-white w-0.5 mb-auto h-full md:col-span-1 hidden md:block shadow-white" />
        <span className="text-glow mt-8 md:mt-0 md:pb-16 md:col-span-8 text-left">{entry.text}</span>
    </article>
)

export default LogEntry