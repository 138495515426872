import Row, { Row as RowType } from "../Row"

export interface Column {
    width: 'full' | 'twoThirds' | 'oneThird'
    alignment: 'left' | 'center' | 'right'
    row: RowType[]
    id: string
}

export const alignment = (align: 'left' | 'center' | 'right') => {
    switch (align) {
        case 'left':
            return 'justify-start text-center lg:text-left'
        case 'center':
            return 'justify-center text-center'
        case 'right':
            return 'justify-end text-center lg:text-right'
        default:
            return 'justify-start text-center lg:text-left'
    }
}


const width = (width: 'full' | 'oneThird' | 'twoThirds') => {
    switch (width) {
        case 'full':
            return 'basis-full'
        case 'twoThirds':
            return 'basis-7/12'
        case 'oneThird':
            return 'basis-5/12 order-first lg:order-none'
        default:
            return 'basis-7/12'
    }
}

const Column = ({ column }: { column: Column }) =>
    <div className={`${alignment(column.alignment)} ${width(column.width)}`}>
        {column.row.map((x: RowType) => <Row align={column.alignment} row={x} key={x.id} />)}
    </div>

export default Column