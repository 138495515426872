import { AnimatePresence, motion } from "framer-motion"
import Router from 'next/router'
import { FC, useState } from "react"
import { TbCross, TbX } from "react-icons/tb"



const PreviewMessage = () => {

    const [open, setOpen] = useState(true)

    const exit = async () => {
        await fetch(`api/preview`, { method: 'PATCH' })
            .then(_ => _.status === 200 && Router.reload())
    }

    return (
        <AnimatePresence
            initial={true}
            onExitComplete={() => null}>
            <motion.div animate={{ y: open ? 0 : 200, opacity: open ? 1 : 0, transition: { duration: 0.6, type: 'spring', bounce: 0.2 } }} style={{
                zIndex: 60, height:
                    '95vh'
            }} className="fixed w-sceen flex right-4">
                <div className="bg-white shadow-xl backdrop-blur-sm items-start py-4 mb-24 mt-auto flex">
                    <div className="ml-6">
                        <h5>Preview Mode</h5>
                        <p className="text-base text-black/50 max-w-xs ">You are currently in preview mode. This message won&apos;t be displayed when your changes are published.</p>
                        <div className="flex space-x-2 mt-4">
                            <button onClick={exit} className="text-white-100 text-sm font-bold text-white bg-black py-2 px-6 rounded duration-150 hover:opacity-80 ">
                                Exit preview mode
                            </button>
                        </div>
                    </div>
                    <button className=" duration-150 hover:bg-gray-100/10 rounded-full mr-6" onClick={() => setOpen(false)}>
                        <TbX className="text p-1 text-2xl" />
                    </button>
                </div>
            </motion.div>
        </AnimatePresence>)
}


export default PreviewMessage